var nextSeoDefaultProps = {
  title: 'Cursos online para aprender nuevas habilidades hoy',
  titleTemplate: '%s | Crehana',
  description: 'Cursos online de marketing, fotografía, negocios, ilustración, diseño, animación y más. Mejora tus habilidades y aprende online con expertos de cada industria.',
  facebook: {
    appId: '375346295993240'
  },
  defaultOpenGraphImageWidth: 1200,
  defaultOpenGraphImageHeight: 628,
  openGraph: {
    type: 'website',
    site_name: 'https://www.crehana.com',
    images: [{
      url: 'https://static.crehana.com/static/img/logo/logo-crehana.6a48047513e0.png',
      alt: 'Crehana'
    }]
  },
  twitter: {
    handle: '@crehanacom',
    site: '@crehanacom',
    cardType: 'summary_large_image'
  },
  additionalMetaTags: [{
    name: 'author',
    content: 'Crehana'
  }, {
    name: 'copyright',
    content: 'Copyright 2022 by Crehana'
  }, {
    name: 'distribution',
    content: 'global'
  }, {
    name: 'google-site-verification',
    content: 'H9hsebJVyxa-jE-9J_EWfhSICMfof1LHQPNb04cbD8M'
  }, {
    name: 'theme-color',
    content: '#4B22F4'
  }, {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'black-translucent'
  }, {
    name: 'application-name',
    content: 'Crehana'
  }, {
    name: 'apple-mobile-web-app-title',
    content: 'Crehana'
  }, {
    name: 'twitter:creator',
    content: 'Crehana'
  }, {
    name: 'twitter:domain',
    content: 'crehana.com'
  }]
};
export default nextSeoDefaultProps;