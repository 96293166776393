/**
 * Useful to apply extra classNames to an element avoiding duplicates
 * It compares prefixes to keep only the ones applied on the base classes string.
 *
 * @param {string} baseCls - The classNames that will prevail
 * @param {string} extraCls - Extra classNames, duplicates will be ignored
 *
 * @example
 * const baseCls = 'cls1 cls2 cls3 py-16';
 * const extraCls = 'cls3 cls4 py-24';
 * const finalCls = parseClassnames(baseCls, extraCls);
 *
 * console.log(finalCls); // cls1 cls2 cls3 cls4 py-16
 */
var parseClassnames = function parseClassnames(baseCls, extraCls) {
  var baseClasses = baseCls ? baseCls.split(' ') : [];
  var extraClasses = extraCls ? extraCls.split(' ') : [];
  var classNames = baseClasses.join(' ');
  extraClasses.forEach(function (extra) {
    if (!extra) {
      return;
    }
    var cls = extra.substr(0, 1) === '-' ? extra.substring(1) : extra;
    var prefix = cls.replace('tw-', '').split('-')[0];
    var repeated = baseClasses.find(function (c) {
      return c.includes("".concat(prefix, "-"));
    });
    if (!repeated) {
      classNames = classNames.concat(classNames.length ? ' ' : '', extra);
    }
  });
  return classNames;
};
export default parseClassnames;